import ss_1 from '../../images/safespace/4T0A9344.jpg';
import ss_2 from '../../images/safespace/4T0A9352.jpg';
import ss_3 from '../../images/safespace/4T0A9366.jpg';
import ss_4 from '../../images/safespace/4T0A9367.jpg';
import ss_5 from '../../images/safespace/4T0A9464.jpg';
import ss_6 from '../../images/safespace/4T0A9488.jpg';
import ss_7 from '../../images/safespace/4T0A9570.jpg';
import ss_8 from '../../images/safespace/4T0A9638.jpg';
import ss_9 from '../../images/safespace/4T0A9645.jpg';
import ss_10 from '../../images/safespace/4T0A9729.jpg';
import ss_11 from '../../images/safespace/4T0A9732.jpg';
import ss_12 from '../../images/safespace/4T0A9767.jpg';
import ss_13 from '../../images/safespace/4T0A9781.jpg';
import ss_14 from '../../images/safespace/4T0A9783.jpg';
import ss_15 from '../../images/safespace/4T0A9785.jpg';

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function SafeSpace() {
    return (
        <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={8}>
                <img src={ss_4} />
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>Safe_Space</Typography>
                    <Typography>Knit Cotton Sculpture, Pneumatic Actuators, Paracord, Digital Control System, Human
                        Performer</Typography>
                    <Typography>Safe_Space is an exploration of the membrane between technology and the body. Safe_Space
                        is the product of a collaboration with Boston-based artist and choreographer <a
                            href="http://www.alexanderdavis.dance/">Alexander Davis</a>. Safe_Space was seen in the Migration exhibition at the Umbrella Community Arts Center in
                        Concord, MA.</Typography>
                    <Typography>Performer Meg Anderson featured in photos.</Typography>
                </div>
            </Grid>
            <Grid item xs={4}>
                <img src={ss_3} />
            </Grid>
            <Grid item xs={4}>
                <img src={ss_15} />
            </Grid>
            <Grid item xs={4}>
                <img src={ss_13} />
            </Grid>
            <Grid item xs={6}>
                <img src={ss_8} />
            </Grid>
            <Grid item xs={6}>
                <img src={ss_6} />
            </Grid>
        </Grid>
    )
}

export default SafeSpace;