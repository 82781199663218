import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import mmpc_1 from '../../images/mmpc/mmpc_1.png';
import mmpc_2 from '../../images/mmpc/mmpc_2.png';

function Mmpc() {
    return (
        <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={6}>
                <img src={mmpc_1} />
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>MMPC</Typography>
                    <Typography>Audio / Visual Installation, Isolation Chamber, Tactile Transducer</Typography>
                    <Typography>Created in collaboration with artist Eric Miller, MMPC (multi-modal perceptual chamber) is an installation constructed as an audio/visual/tactile experience housed inside of an isolation chamber.</Typography>
                    <Typography>Along with four speakers placed around the participant's head, a tactile transducer produces vibration and tactile feedback. A large LED display (consisting of nearly 400 individually addressable RGB LEDs) is in front of the participant. Together, these forms of perceptual experience combine in a tightly synced experience that is at moments soothing and meditative and at others, pointed and driving. </Typography>
                    <Typography>Premiered at Collar Works Gallery in Troy, NY in May 2017.</Typography>
                </div>
            </Grid>
            <Grid item xs={6}>
                <img src={mmpc_2} />
            </Grid>
        </Grid>
    )
}

export default Mmpc;