import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import fr_1 from '../../images/frame_recursion/FR_Dress_Edited10.png';
import fr_2 from '../../images/frame_recursion/IMG_7563.JPG';
import fr_3 from '../../images/frame_recursion/IMG_7647.jpg';

function FrameRecursion() {
    return (
        <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12}>
                <img src={fr_1} />
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>
                        Frame Recursion
                    </Typography>

                    <Typography>
                        Frame Recursion explore theories of self-concept and identity by researcher John Hattie, as well as connected historical theories developed by philosopher William James and sociologist Charles Cooley. Frame Recursion explores the conceptualization of oneself as it is affected by changing social realities and demonstrates a movement towards hope, reflection, and connection.
                    </Typography>
                    <Typography>
                        Created by choreographer and Urbanity Dance Artistic Associate Meg Anderson in collaboration with multimedia artist Jeremy Stewart and the dancers, Frame Recursion explores each dancer’s evolving self concept, drawing on their personal experiences during the pandemic. Self-concept and growth are further explored through multi-channel video projections, created from recordings of each dancer.
                    </Typography>
                    <Typography>
                        Frame Recursion premiered on May 21, 2021 in Boston, MA. This project was made possible in part through the support of MASARY Studios and William Neely.
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={6}>
                <img src={fr_2} />
            </Grid>
            <Grid item xs={6}>
                <img src={fr_3} />
            </Grid>
        </Grid>
    )
}

export default FrameRecursion;