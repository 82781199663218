import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import { Link } from 'react-router-dom';

import { useState } from "react";

function SiteMenu() {
    const [showProjectsMenu, setShowProjectsMenu] = useState(true);

    const render_projects_menu = () => {
        if (showProjectsMenu) {
            return (
                <Stack direction={"column"} spacing={1} className={"projects-menu"}>
                    <Typography>
                        <Link to={"/cibo"}>
                            Cibo
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/fieldcuts_5"}>
                            Field Cuts: 5
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/fieldcuts_6"}>
                            Field Cuts: 6
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/frame_recursion"}>
                            Frame Recursion
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/live_coding"}>
                            Live Coding
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/mmpc"}>
                            MMPC
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/packet_injection"}>
                            Packet Injection
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/pl1"}>
                            pl:1
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/pl2"}>
                            pl:2
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/prometheus"}>
                            Prometheus
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/refractive_choreographies"}>
                            Refractive Choreographies
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/safe_space"}>
                            Safe Space
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/scp-079"}>
                            SCP-079
                        </Link>
                    </Typography>

                    <Typography>
                        <Link to={"/we_all_fall_down"}>
                            We All Fall Down
                        </Link>
                    </Typography>

                </Stack>
            )
        } else {
            return null;
        }
    }

    return (
        <Stack direction={"column"} spacing={1}>
            <Typography>
                <Link to={"/about"}>
                    About
                </Link>
            </Typography>
            <Typography>
                {/*<Link to={"#"} onClick={() => {*/}
                {/*    setShowProjectsMenu(prevState => {*/}
                {/*        return !prevState;*/}
                {/*    })*/}
                {/*}}>Projects</Link>*/}
                Projects
                {render_projects_menu()}
            </Typography>
        </Stack>
    )
}

export default SiteMenu;