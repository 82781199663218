import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import pi_1 from '../../images/packet_injection/IMG_20191026_210050.jpg';

function PacketInjection() {
    return (
        <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12}>
                <img src={pi_1} />
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>
                        Packet Injection
                    </Typography>
                    <Typography>Dance performance. Created in collaboration with Meg Anderson (director / choreographer) and the dancers. Performed as part of <em>FACTS</em>, an interdisciplinary dance crawl in Boston's South End neighborhood, October 24 - 26, 2019.</Typography>
                    <Typography>This piece features three members of Urbanity Dance's Junior Apprentices program performing within an audio/video installation featuring 16 TV screens, interactive lighting, original sound score. While wearing motion sensors, heart rate monitors, and microprocessors, the dancers move in relation to video and light, their heart rate affecting the distorted images shown on each screen.</Typography>
                </div>
            </Grid>
        </Grid>
    )
}

export default PacketInjection;