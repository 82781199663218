import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import pl1_1 from '../../images/pl1/IMG_1108.JPG';
import pl1_2 from '../../images/pl1/IMG_1111.JPG';
import pl1_3 from '../../images/pl1/IMG_1112.JPG';
import pl1_4 from '../../images/pl1/IMG_2286.JPG';

function Pl1() {
    return (
        <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12}>
                <img src={pl1_2}/>
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>PL:1</Typography>
                    <Typography>Video Projection, Electrical Stimulation, Solo Performer [performance installation,
                        duration: 120 minutes]</Typography>
                    <Typography>As we become more embedded in complex data networks, and as the operational mode and
                        speed of these networks develop further away from what we can consciously access, the role of
                        consciousness itself is called into question.</Typography>
                    <div className={"block-quote"}>
                        <blockquote>The resulting scenario involves a distinct displacement of our agency: acting
                            through our conscious grasp of situations, we simply cannot have
                            direct <em>operational</em> or <em>"real-time"</em> access to the data milieus of culture
                            products [...] We thus find ourselves faced with the imperative to respond -- to take
                            deliberate action and to make conscious decisions -- <em>in situations where deliberation is
                                no longer the relevant mode of response and where consciousness is no longer the
                                relevant level of experience</em>.
                        </blockquote>
                        <Typography className={"right-align-text"}>Mark B. N. Hansen, Feed Forward: On the Future of
                            Twenty-First-Century Media
                            (2015)</Typography>
                    </div>
                    <Typography>The connected performer attempted to carry out a series of movements with objects as
                        directed by visual, auditory, and tactile feedback. While attempting to make sense of feedback
                        from the system, the performer remains unaware of the meaning of his actions or of the role he
                        is playing.</Typography>
                    <Typography>Video projection and camera installed overhead. All software built using
                        openFrameworks.</Typography>
                    <Typography>System runs based on preprogrammed behaviors which are chosen based on probability
                        tables and the
                        performer's actions. Each behavior has a unique way of assessing the current arrangement of
                        objects and issuing instructions to the performer. Some behaviors are only interested in adding
                        new objects, others with removing objects from the table, and still others focus on avoiding or
                        moving towards set regions. The system periodically reads the table for object placement,
                        comparing the current state of real-world objects against the target arrangement created by the
                        program. </Typography>
                    <Typography>Performer is wearing electrodes on his upper body, allowing electrical impulses to be
                        delivered
                        directly to his nerves and muscles based on his success or failure to complete assigned
                        instructions.</Typography>
                </div>
            </Grid>
            <Grid item xs={6}>
                <img src={pl1_1} />
            </Grid>
            <Grid item xs={6}>
                <img src={pl1_4} />
            </Grid>
        </Grid>
    )
}

export default Pl1;