import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";

import wafd_1 from '../../images/we_all_fall_down/VQVAE_Sample_from.png';

function WeAllFallDown() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <img src={wafd_1}/>
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>We All Fall Down</Typography>
                    <Typography>Live-streamed dance for camera performance debuted by NobleMotion Dance on August
                        7 &amp; 8, 2020.</Typography>
                    <Typography>This performance features 10 sections, each with different performers
                        in different locations, interspersed with imagery generated by artificial intelligence (A.I.)
                        and machine learning (M.L.) systems.</Typography>
                    <Typography>Original choreography by Andy and Dionne Noble. Original
                        music by Christopher Cerrone, Alex Davis, Michael Wall, and Travis Lake. A.I.-generated
                        video by Jeremy Stewart.</Typography>
                    <Typography>Performers: Wesley Cordova, Rachel Cox Culver, Joshua DeAlba, Brit Deveau, Julia
                        Discenza, Rhodes Elliot, La’Rodney Freeman, Atticus Griffin, Shohei Iwahama, Colette Kerwick,
                        Kalli Loudan, Lindsey McGill, Brit Wallis-McGrath, Genene Wallis-McGrath, Tyler Orcutt, Evelyn
                        Toh Paoli, Lauren Serrano, and Joseph Stevens, w/Kambi Gathesha and Vincent Callero</Typography>
                    <Typography><a href="http://www.noblemotiondance.com/about/weallfalldown/">More information can be
                        found through NobleMotion Dance.</a></Typography>
                </div>
            </Grid>
        </Grid>
    )
}

export default WeAllFallDown;