import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import rc_1 from '../../images/rc/khris_.png';
import rc_2 from '../../images/rc/olivia_dope.png';
import rc_3 from '../../images/rc/RC5_Allston_PhotobyAramBoghosian_5.jpg';
import rc_4 from '../../images/rc/RC_folks.jpg';
import rc_5 from '../../images/rc/RCNight4_14.jpg';
import rc_6 from '../../images/rc/vlcsnap-2020-05-08-15h41m54s058.png'

function RefractiveChoreographies() {
    return (
        <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={8}>
                <img src={rc_3}/>
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>Refractive Choreographies</Typography>
                    <Typography>Dance-video project created in collaboration with Meg Anderson, <a
                        href="https://www.urbanitydance.org/" target="_blank" rel="noreferrer noopener">Urbanity
                        Dance</a>, and <a href="http://www.masarystudios.com/" target="_blank"
                                          rel="noreferrer noopener">MASARY Studios</a>. This work features the members
                        of the Urbanity Dance professional company, original music by Ryan Edwards, and A.I. generated
                        video across five movements. </Typography>
                    <Typography><em>Refractive Choreographies </em>was presented as a pop-up video projection series in
                        Boston, across five different neighborhoods over the course of five nights. <em>Refractive
                            Choreographies</em> was seen in Boston's South End, Chinatown, Chelsea, Dorchester, and
                        Allston neighborhoods, at numerous locations in each neighborhood.</Typography>
                    <Typography>The video works present the professional company dancers performing in a mediated
                        environment filled with evolving digital representations of the performers projected onto the
                        screen, as well as a growing Artificial Intelligence agent that learns from the performers. The
                        AI agent mimics the human performers, moving with them and in response to their choreography,
                        while producing its own inhuman artifact as a result of its learning processes.</Typography>
                    <Typography>Each piece can be viewed in isolation as a freestanding choreographic statement, where
                        the projected dancer confronts their own consumption by digital media technologies. Over the
                        course of the series, the artificial intelligence agents evolve and come to play a more
                        immediate and present role in the work, with the digital representation of the human performer
                        moving with the AI agent that has learned from each dancer’s movements and
                        gestures.</Typography>
                    <Typography>Additional information can be found at <a
                        href="http://www.masarystudios.com/projects#/refractive-choreographies/">MASARY
                        Studios</a> and <a href="https://www.urbanitydance.org/refractive-choreographies">Urbanity
                        Dance</a></Typography>
                    <Typography>Directed by Meg Anderson (creative direction / choreography); Urbanity Professional
                        Company Dancers; Jeremy Stewart (video &amp; AI programming); Ryan Edwards (sound
                        composition), Sam Okerstrom-Lang (video projection); <a
                            href={"http://rob-eckel.squarespace.com"}>Rob Eckel</a> (videography / documentation);
                        <a href={"https://www.aramphoto.com/index/G0000rj597YZqxjk"}>Aram
                            Boghosian</a> (photography)</Typography>
                    <Typography>Top photo and bottom two photos by Aram Boghosian.</Typography>
                </div>
            </Grid>
            <Grid item xs={4}>
                <img src={rc_1}/>
            </Grid>
            <Grid item xs={4}>
                <img src={rc_2}/>
            </Grid>
            <Grid item xs={4}>
                <img src={rc_6}/>
            </Grid>
            <Grid item xs={6}>
                <img src={rc_4}/>
            </Grid>
            <Grid item xs={6}>
                <img src={rc_5}/>
            </Grid>
        </Grid>
    )
}

export default RefractiveChoreographies;