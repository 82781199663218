import Typography from "@mui/material/Typography";

function AboutPage() {
    return (
        <div className={"about-text"}>
            <Typography>Jeremy Stewart is a multimedia artist and performer researching the affective potential of
                distributed
                media
                systems through the creation of improvisational performances, artificial intelligence (A.I.) software,
                and
                wearable hardware. His work investigates the ways that technology can affect, interact with, and alter
                an
                individual’s agency, perception, and autonomy. </Typography>
            <Typography>Stewart is the technical director and a contributing artist at Boston-based MASARY Studios. At
                MASARY
                Studios, he
                has led the creative development of projects including <a
                    href={"https://www.masarystudios.com/phase-garden"}>Phase Garden</a>, <a
                    href={"https://www.masarystudios.com/projects#/memory-diffusion/"}>Memory / Diffusion</a>, <a
                    href={"https://www.masarystudios.com/projects#/data-choreographics/"}>Data Choreographics</a>, <a
                    href={"https://www.masarystudios.com/projects#/of-the-ether/"}>Of The Ether</a>, and <a
                    href={"https://www.masarystudios.com/projects#/hybridagenciesproject/"}>Hybrid Agencies</a>.

                He has also led the technical development and oversight of projects studio-wide, including projects such
                as <a href={"https://www.masarystudios.com/projects#/ritual-system/"}>Ritual System</a>, <a
                    href={"https://www.masarystudios.com/projects#/sound-sculpture/"}>SoundSculpture</a>, <a
                    href={"https://www.masarystudios.com/projects#/hdbpm/"}>HDBPM</a>, and <a
                    href={"https://www.masarystudios.com/projects#/figuration/"}>Figuration</a>, the web-based
                audio/visual instrument <a href={"https://md.place/"}>Massively Distributed</a>, and ALOHA, a custom
                live-streaming platform enabling low-latency event broadcast with custom interactive
                components.</Typography>
            <Typography>Stewart's dissertation, <em>Art of Art of Art: An Artistic Practice with Artificial
                Intelligence</em>, investigates the use of artificial intelligence technologies within the context of an
                artistic practice, both as a technology and tool to be implemented and used for creative expression, and
                as a theoretical framework to be explored through creative strategies. He completed his PhD in the
                Electronic Arts Program at Rensselaer Polytechnic Institute in 2021, under the guidance of <a
                    href={"https://www.shawnlawson.com/"}>Professor Shawn Lawson</a></Typography>
            <Typography>Contact: jeremy.ste@gmail.com</Typography>
        </div>
    )
}

export default AboutPage;