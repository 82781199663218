import {Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import {createTheme, ThemeProvider} from '@mui/material/styles';

import SiteMenu from "./SiteComponents/SiteMenu";
import AboutPage from "./SiteComponents/AboutPage";

import './sitetheme.css';
import fc6_1 from './images/fc6/fieldcuts6_1.jpg';

import {
    BrowserRouter as Router, Link, Route, Switch
} from 'react-router-dom';
import Typography from "@mui/material/Typography";
import FieldCuts6 from "./SiteComponents/ProjectPages/FieldCuts6";
import FieldCuts5 from "./SiteComponents/ProjectPages/FieldCuts5";
import SafeSpace from "./SiteComponents/ProjectPages/SafeSpace";
import WeAllFallDown from "./SiteComponents/ProjectPages/WeAllFallDown";
import Scp079 from "./SiteComponents/ProjectPages/Scp079";
import LiveCoding from "./SiteComponents/ProjectPages/LiveCoding";
import Cibo from "./SiteComponents/ProjectPages/Cibo";
import Mmpc from "./SiteComponents/ProjectPages/Mmpc";
import PacketInjection from "./SiteComponents/ProjectPages/PacketInjection";
import RefractiveChoreographies from "./SiteComponents/ProjectPages/RefractiveChoreographies";
import Pl1 from "./SiteComponents/ProjectPages/Pl1";
import Pl2 from "./SiteComponents/ProjectPages/Pl2";
import Prometheus from "./SiteComponents/ProjectPages/Prometheus";
import FrameRecursion from "./SiteComponents/ProjectPages/FrameRecursion";

const theme = createTheme({
    typography: {
        h1: {
            fontSize: 24,
            paddingBottom: '2rem',
        },
        h2: {
            fontSize: 24,
            paddingBottom: '1rem',
        }
    },
});


function App() {
    return (
        <ThemeProvider theme={theme}>
            <Container className="App" maxWidth={'xl'}>
                <Router>
                    <Typography variant={"h1"}>
                        <Link to={'/'}>
                            blindelephants.co
                        </Link>
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <SiteMenu/>
                        </Grid>
                        <Grid item xs={10}>
                            <Switch>
                                <Route path={"/about"}>
                                    <AboutPage/>
                                </Route>
                                <Route path={"/fieldcuts_5"}>
                                    <FieldCuts5 />
                                </Route>
                                <Route path={"/fieldcuts_6"}>
                                    <FieldCuts6 />
                                </Route>
                                <Route path={"/safe_space"}>
                                    <SafeSpace />
                                </Route>
                                <Route path={"/we_all_fall_down"}>
                                    <WeAllFallDown/>
                                </Route>
                                <Route path={"/scp-079"}>
                                    <Scp079 />
                                </Route>
                                <Route path={"/live_coding"}>
                                    <LiveCoding />
                                </Route>
                                <Route path={"/cibo"}>
                                    <Cibo />
                                </Route>
                                <Route path={"/mmpc"}>
                                    <Mmpc />
                                </Route>
                                <Route path={"/packet_injection"}>
                                    <PacketInjection />
                                </Route>
                                <Route path={"/refractive_choreographies"}>
                                    <RefractiveChoreographies />
                                </Route>

                                <Route path={"/pl1"}>
                                    <Pl1 />
                                </Route>

                                <Route path={"/pl2"}>
                                    <Pl2 />
                                </Route>

                                <Route path={"/prometheus"}>
                                    <Prometheus />
                                </Route>

                                <Route path={"/frame_recursion"}>
                                    <FrameRecursion />
                                </Route>

                                <Route path={"/"}>
                                    <img src={fc6_1} alt={"Field Cuts: Encounter 6"}/>
                                </Route>
                            </Switch>
                        </Grid>
                    </Grid>
                </Router>
            </Container>
        </ThemeProvider>
    );
}

export default App;
