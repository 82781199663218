import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import pl2_1 from '../../images/pl2/pl2_image_WEB_1.png';
import pl2_2 from '../../images/pl2/pl2_image_WEB_2.png';
import pl2_3 from '../../images/pl2/pl2_image_WEB_3.png';

function Pl2() {
    return (
        <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12}>
                <img src={pl2_1}/>
            </Grid>
            <Grid item xs={12}>
                <div className={'project-text'}>
                    <Typography variant={'h2'}>PL:2</Typography>
                    <Typography>Two Performers, Web Server, Mobile Web Application, Sound, Video Projection, Embedded
                        Hardware [performance, duration: ~40 minutes]</Typography>
                    <Typography>A complex network of interrelated nodes feeding into the ever-shifting tectonics of an
                        autonomous, black-boxed, digital system, <em>pl:2 </em>
                        seeks to make complicit the performer and the observer in a topology of
                        influence and shared agency while questioning the individual’s role,
                        and, more importantly, responsibility, when interfacing with such
                        systems. While the seemingly (and debatably) innocuous use of embedded,
                        personal networked devices feeds the cultural fetishization of the self,
                        we are rarely met, face- to-face, with the implications of our actions
                        in such digital systems.</Typography>
                    <Typography>When audience members log on, through their personal mobile devices,
                        to the locally hosted web server, they are met with an interactive
                        interface. Through tapping, clicking, and dragging—all actions that have
                        become a part of our digital lexicon and neocyborgian embodiment—users
                        influence the larger audio/video installation, producing real-time sound
                        and video events, reifying a sense of location, connection, and
                        involvement, satiating the individual desire for (artificial?) social
                        existence.</Typography>
                    <Typography>Two performers enter. Still at first, then restrained in movement.
                        Responding as they receive tactile feedback through any one of an array
                        of vibrational motors held against their bodies, moving according to
                        rules and laws provided through auditory messages directly to their
                        headphones. As mobile-device-users continue to tap/click/jab/drag, the
                        performers move faster, further, louder—slower, smaller, softer.</Typography>
                    <Typography>Created in collaboration with performers Meghan Anderson, Haley Day, and <a
                        href="file:///home/jeremy/Documents/Website_Backup_11162018/blindelephants.co/performance/2017/05/01/www.alexanderdavis.dance">Alexander
                        Davis</a>.</Typography>
                    <Typography>Premiered at The Curtis R. Priem Experimental Media and Performing Arts Center (EMPAC) Studio 2
                        in Troy, NY, on April 27, 2017.</Typography>
                    <Typography>Performed at the International Conference for Live Coding 2017 in Morelia, Mexico on December 6,
                        2017.</Typography>
                </div>
            </Grid>
            <Grid item xs={6}>
                <img src={pl2_2} />
            </Grid>
            <Grid item xs={6}>
                <img src={pl2_3} />
            </Grid>
        </Grid>
    )
}

export default Pl2;