import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import cibo_1 from '../../images/cibo/TidalImage.png';

function Cibo() {
    return (
        <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12}>
                <img src={cibo_1}/>
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={'h2'}>Cibo</Typography>
                    <Typography>
                        Cibo is a live coding A.I. agent that performs using the TidalCycles library. Cibo has been
                        presented or performed
                        solo and with human performers at events including the International Conference on Live Coding
                        (ICLC), Madrid, Spain, 2019; Live Ideas, New York Live Arts, 2019; and ICLC, Limerick,
                        Ireland, 2020.
                    </Typography>
                    <Typography>
                        Cibo has been written about in its various forms:
                    </Typography>
                    <Typography className={"indent-text"}>Stewart, Jeremy and Shawn Lawson. "Cibo: An Autonomous TidalCycles Performer." Paper presented at the Fourth International Conference on Live Coding (ICLC), Madrid, Spain, January 2019.</Typography>
                    <Typography className={"indent-text"}>Stewart, Jeremy, Shawn Lawson, Mike Hodnick, and Ben Gold. "Cibo v2: Realtime Livecoding AI Agent." Paper presented at the Fifth International Conference on Live Coding (ICLC), Limerick, Ireland, February 2020.</Typography>
                    <Typography className={"indent-text"}>Stewart, Jeremy. "Art of Art of Art: An Artistic Practice with Artificial Intelligence." PhD diss., Rensselaer Polytechnic Institute, 2021.</Typography>
                </div>
            </Grid>
        </Grid>
    )
}

export default Cibo;