import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import livecoding_1 from '../../images/livecoding/32899202_442517482867535_7576480678473105408_o_442517476200869-1920x1080.jpg';
import livecoding_2 from '../../images/livecoding/32899202_442517482867535_7576480678473105408_o_442517476200869.jpg'

function LiveCoding() {
    return (
        <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12}>
                <img src={livecoding_1} />
            </Grid>

            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>Live Coding</Typography>
                    <Typography>Stewart maintains an active practice as a Live coder, primarily performing with <a href="https://tidalcycles.org/">TidalCycles</a>, along with combinations of SuperCollider, Ableton Live, Max/MSP, and custom written software. </Typography>
                    <Typography>Stewart is interested in bringing together aspects of jazz melodic/harmonic structures and sound samples from the video game systems he grew up playing (including Sega Genesis, Nintendo, SNES, and PC games).</Typography>
                    <Typography>Performances include: ICLC (International Conference on Live Coding) in Morelia, Mexico, 2017; the New York City Eleectroacousitc Music Festival at Abrons Art Center, in New York City, 2017; Bennington College; Bard College; Collarworks Gallery in Troy, NY; the Tech Valley Center of Gravity, in Troy, NY.</Typography>
                    <Typography>Performances with: <a href="http://kmichaelfox.com/">K. Michael Fox</a>, <a href="http://ryanrosssmith.com/">Dr. Ryan Ross Smith</a>, <a href="http://www.shawnlawson.com/">Shawn Lawson</a>.</Typography>
                </div>
            </Grid>
        </Grid>
    )
}

export default LiveCoding;