import fc6_1 from '../../images/fc6/fieldcuts6_1.jpg';
import fc6_2 from '../../images/fc6/fieldcuts6_2.jpg';
import fc6_3 from '../../images/fc6/fieldcuts6_3.jpg';
import fc6_4 from '../../images/fc6/fieldcuts6_4.jpg';
import fc6_5 from '../../images/fc6/fieldcuts6_5.jpg';
import fc6_6 from '../../images/fc6/fieldcuts6_6.jpg';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function FieldCuts6() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <img src={fc6_2} />
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>Field Cuts: Encounter 6</Typography>
                    <Typography>Video Projection, Sound, Electrical Stimulation, Three Performers [Duration: 35 minutes]</Typography>
                    <Typography>Three performers move while being influenced by each other at a distance through virtual, fleeting, affective connections, made real and tactile through momentary electrical stimulation. These virtual connections are drawn between performers, connecting movement of one to tactile experience of another. Performers respond: consciously and preperceptually, movement is altered, compositional forms unfold. Performers navigate an improvisational framework: changing movement, pattern, spatial orientation.</Typography>
                    <Typography>Created in collaboration with sound designer <a href="http://kmichaelfox.com/">K. Michael Fox</a> and performers Meghan Anderson, Jacob Regan, and Haley Day. </Typography>
                    <Typography>Performed at the <a href={"https://empac.rpi.edu/"}>Curtis R. Priem Experimental Media and Performing Arts Center</a>, Studio 2, Troy, NY on March 10, 2016.</Typography>
                </div>
            </Grid>
            <Grid item xs={4}>
                <img src={fc6_3} />
            </Grid>
            <Grid item xs={4}>
                <img src={fc6_4} />
            </Grid>
            <Grid item xs={4}>
                <img src={fc6_5} />
            </Grid>
            <Grid item xs={6}>
                <img src={fc6_6} />
            </Grid>
            <Grid item xs={6}>
                <img src={fc6_1} />
            </Grid>
        </Grid>
    )
}

export default FieldCuts6;