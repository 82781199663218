import fc5_1 from '../../images/fc5/Screenshot-at-2018-11-16-14-42-52.png';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function FieldCuts5() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <img src={fc5_1}/>
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>Field Cuts: Encounter 5</Typography>
                    <Typography>Electrical Stimulation, Sound, Three Performers [Duration: 30 minutes]</Typography>
                    <Typography>Electrically augmented movement performance. Performers influence each other at a
                        distance, sharing their physical agency with each other, raising questions of communication,
                        control, and affect.</Typography>
                    <Typography>Created in collaboration with sound designer <a href="http://kmichaelfox.com/">K.
                        Michael Fox</a> and performers Meghan Anderson and Jacob Regan.</Typography>
                </div>
            </Grid>
        </Grid>
    )
}

export default FieldCuts5;