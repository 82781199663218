import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import scp079_1 from '../../images/scp_079/ImgGAN_InterpBilinear_shrunk.png';
import scp079_2 from '../../images/scp_079/gen_3880.png';

function Scp079() {
    return (
        <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={6}>
                <img src={scp079_2} />
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>SCP-079</Typography>
                    <Typography></Typography>
                    <Typography><em>SCP-079</em> is an A.I. agent that generates video in real time for audio-visual performance. Video is generated in real time using a multi-module machine learning agent which responds to live audio input.</Typography>
                    <Typography><em>SCP-079</em> has performed with Liveware (Michael Century and Shawn Lawson) at Arete Gallery, Brooklyn, NY; and at the Center for Computer Research in Music and Acoustics (CCRMA) at Stanford University, Stanford, CA.</Typography>
                </div>
            </Grid>
            <Grid item xs={6}>
                <img src={scp079_1}/>
            </Grid>
        </Grid>
    )
}

export default Scp079;