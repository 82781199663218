import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import p2 from '../../images/prometheus/FS9A0049-2.png';
import p3 from '../../images/prometheus/FS9A0096-2.png';
import p4 from '../../images/prometheus/FS9A0113-2.png';
import p6 from '../../images/prometheus/MVIMG_20190606_103743.png';

function Prometheus() {
    return (
        <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12}>
                <img src={p2} />
            </Grid>
            <Grid item xs={12}>
                <div className={"project-text"}>
                    <Typography variant={"h2"}>
                        Prometheus
                    </Typography>
                    <Typography>Prometheus pits artificial intelligence and machine learning agents at odds with the moving body, drawing inspiration from the classic Greek tale of man being given the gift of the gods: fire. Intensely human, <em>Prometheus</em> explores influence, attention, autonomy, awareness, morality, choice, and intuition. </Typography>
                    <Typography>This work is created in collaboration with Houston-based <a href="https://www.noblemotiondance.com/">NobleMotion Dance</a>, choreographers Andy and Dionne Noble, light designer and technology artist David Deveau, industrial design artist Jared Doster, and sound artist Bryan Ealey.</Typography>
                    <Typography>The technology used to produce Prometheus builds upon <a href="/scp-079">SCP-079</a> to include real-time camera input and analysis in order to create a real-time, interactive A.I. video agent which responds to performer movement. The A.I. agent is trained using dance photographs and video, resulting in generated output that highly resembles the human form and movement.</Typography>
                </div>
            </Grid>
            <Grid item xs={6}>
                <img src={p4} />
            </Grid>
            <Grid item xs={6}>
                <img src={p6} />
            </Grid>
            <Grid item xs={12}>
                <img src={p3} />
            </Grid>
        </Grid>
    )
}

export default Prometheus;